import '@blixem/cms-bundle/admin/vendor/fontawesome-pro/css/all.min.css';
import { signup } from '@blixem/laposta-bundle/api.js';
import './style/theme.less';
import './cookies.js';
import './gmaps.js';

let speechUtterance = null;
document.addEventListener('click', e => {
    if (!e.target.closest)
    {
        return;
    }

    let toggleContrast = e.target.closest('.toggle-high-contrast');
    if (toggleContrast)
    {
        e.preventDefault();
        if (document.body.classList.contains('high-contrast'))
        {
            document.body.classList.remove('high-contrast');
            localStorage.setItem('contrast', 'normal');
        }
        else
        {
            document.body.classList.add('high-contrast');
            localStorage.setItem('contrast', 'high');
        }
    }

    let readPage = e.target.closest('.read-page');
    if (readPage)
    {
        e.preventDefault();

        if (speechUtterance)
        {
            speechSynthesis.cancel();
            speechUtterance = null;
            readPage.title = 'Pagina voorlezen';
            readPage.querySelector('i').className = 'fa fa-volume';
            let speechControl = document.querySelector('.speech-control');
            speechControl.classList.remove('playing');
            speechControl.classList.remove('active');
            speechControl.classList.remove('paused');
            return;
        }

        // Use Web Speech API to read out #content
        let content = document.getElementById('content');
        if (!content)
        {
            return;
        }

        let text = '';
        // recursively get text content from all elements in #content, and also alt tags of images
        function getText(element)
        {
            if (element.nodeType === Node.TEXT_NODE)
            {
                text += element.textContent + ' ';
            }
            else if (element.nodeType === Node.ELEMENT_NODE)
            {
                if (element.className === 'hero-breadcrumbs')
                {
                    return;
                }

                if (element.tagName === 'IMG')
                {
                    if (element.alt)
                    {
                        text += 'Afbeelding: ' + element.alt + ' ';
                    }
                }

                for (let child of element.childNodes)
                {
                    getText(child);
                }
            }
        }
        getText(content);

        text = text.replace(/\.(\d+)/g, '$1');
        text = text.replace(/(\d+)\-(\d+)/g, '$1 tot $2');
        text = text.replace(/t\/m/g, 'tot en met');
        text = text.replace(/&/g, 'en');
        text = text.replace(/€/g, 'euro');
        text = text.replace(/%/g, 'procent');
        text = text.replace(/m²/g, 'vierkante meter');

        // Replace numbers with words (NL)
        for (let i = 10000; i >= 0; i--)
        {
            let number = i;
            let numberString = '';

            if (number >= 2000)
            {
                let thousands = Math.floor(number / 1000);
                numberString += ['duizend', 'tweeduizend', 'drieduizend', 'vierduizend', 'vijfduizend', 'zesduizend', 'zevenduizend', 'achtduizend', 'negenduizend'][thousands - 1] + ' ';
                number -= thousands * 1000;
            }

            if (number >= 100)
            {
                let hundreds = Math.floor(number / 100);
                numberString += ['honderd', 'tweehonderd', 'driehonderd', 'vierhonderd', 'vijfhonderd', 'zeshonderd', 'zevenhonderd', 'achthonderd', 'negenhonderd', 'duizend', 'elfhonderd', 'twaalfhonderd', 'dertienhonderd', 'veertienhonderd', 'vijftienhonderd', 'zestienhonderd', 'zeventienhonderd', 'achtienhonderd', 'negentienhonderd'][hundreds - 1] + ' ';
                number -= hundreds * 100;
            }

            let tens = Math.floor(number / 10);
            let ones = number % 10;

            if (tens == 1)
            {
                numberString += ['tien', 'elf', 'twaalf', 'dertien', 'veertien', 'vijftien', 'zestien', 'zeventien', 'achttien', 'negentien'][ones] + ' ';
            }
            else
            {
                if (ones > 0)
                {
                    numberString += ['een', 'twee', 'drie', 'vier', 'vijf', 'zes', 'zeven', 'acht', 'negen'][ones - 1] + ' ';
                }

                if (ones > 0 && tens > 0)
                {
                    numberString += 'en ';
                }

                if (tens > 1)
                {
                    numberString += ['tien', 'twintig', 'dertig', 'veertig', 'vijftig', 'zestig', 'zeventig', 'tachtig', 'negentig'][tens - 1] + ' ';
                }
            }

            text = text.replace(new RegExp(i, 'g'), numberString.trim());
        }

        let speechControl = document.querySelector('.speech-control');
        speechControl.classList.add('playing');
        speechControl.classList.add('active');

        speechUtterance = new SpeechSynthesisUtterance(text);
        speechSynthesis.speak(speechUtterance);

        readPage.title = 'Voorlezen stoppen';
        readPage.querySelector('i').className = 'fa fa-volume-slash';

        speechControl.querySelector('.speech-control-pause').focus();

        return;
    }

    let speechControlPause = e.target.closest('.speech-control-pause');
    if (speechControlPause)
    {
        e.preventDefault();
        speechSynthesis.pause();
        let speechControl = document.querySelector('.speech-control');
        speechControl.classList.add('paused');
        speechControl.classList.remove('playing');

        speechControl.querySelector('.speech-control-resume').focus();
    }

    let speechControlResume = e.target.closest('.speech-control-resume');
    if (speechControlResume)
    {
        e.preventDefault();
        speechSynthesis.resume();
        let speechControl = document.querySelector('.speech-control');
        speechControl.classList.add('playing');
        speechControl.classList.remove('paused');
        speechControl.querySelector('.speech-control-pause').focus();
    }

    let speechControlStop = e.target.closest('.speech-control-stop');
    if (speechControlStop)
    {
        e.preventDefault();
        speechSynthesis.cancel();
        let speechControl = document.querySelector('.speech-control');
        speechControl.classList.remove('playing');
        speechControl.classList.remove('paused');
        speechControl.classList.remove('active');
        speechUtterance = null;
        let readPage = document.querySelector('.read-page');
        readPage.title = 'Pagina voorlezen';
        readPage.querySelector('i').className = 'fa fa-volume';
        readPage.focus();
    }

    let harmonicaTitle = e.target.closest('.harmonica-title');
    if (harmonicaTitle)
    {
        if (e.target.closest('.harmonica-title-direct'))
        {
            return;
        }

        e.preventDefault();
        let harmonica = e.target.closest('.harmonica');
        let clickedElemenet = harmonicaTitle.closest('.harmonica-element');
        let currentActive = harmonica.querySelector('.harmonica-element.current');
        if (currentActive && currentActive !== clickedElemenet)
        {
            currentActive.classList.remove('current');
        }
        clickedElemenet.classList.add('current');
    }

    let menuExpand = e.target.closest('.menu-item');
    let menuLink = e.target.closest('.menu-item a');
    if (menuExpand && !menuLink)
    {
        e.preventDefault();
        let menuItem = menuExpand.closest('li');
        menuItem.classList.toggle('open');
    }

    let toggleLocationsMap = e.target.closest('.locations-toggle-map');
    if (toggleLocationsMap)
    {
        e.preventDefault();
        let locations = toggleLocationsMap.closest('.locations');
        locations.classList.toggle('locations-showing-map');
        locations.classList.toggle('locations-showing-list');
    }

    let showLocationsFilters = e.target.closest('.locations-show-filters a');
    if (showLocationsFilters)
    {
        e.preventDefault();
        let locations = document.querySelector('.locations');
        locations.classList.toggle('locations-showing-filters');
    }

    let closeLocationsFilters = e.target.closest('.locations-filters-close');
    if (closeLocationsFilters)
    {
        e.preventDefault();
        let locations = document.querySelector('.locations');
        locations.classList.remove('locations-showing-filters');
    }

    let resetLocationsFilters = e.target.closest('.locations-filters-reset');
    if (resetLocationsFilters)
    {
        e.preventDefault();
        loadLocationFilters('?');
    }

    let signUpFormNext = e.target.closest('.sign-up-next, .sign-up-submit');
    if (signUpFormNext)
    {
        e.preventDefault();

        // Check all fields of current form page are valid
        let signUp = signUpFormNext.closest('.sign-up');
        let currentFormStep = signUp.querySelector('.sign-up-form-step-current');
        if (!currentFormStep)
        {
            return;
        }

        for (let input of currentFormStep.querySelectorAll('input, select, textarea'))
        {
            if (!input.checkValidity())
            {
                input.reportValidity();
                return;
            }

            // Copy value to confirmation table
            let confirmationField = signUp.querySelector('.sign-up-form-preview-field[data-field="' + input.name + '"]');
            if (confirmationField)
            {
                if (input.tagName === 'SELECT')
                {
                    confirmationField.textContent = input.querySelector('option:checked').textContent;
                }
                else if (input.tagName === 'INPUT' && input.type === 'date')
                {
                    // format date as locale-specific date with month names
                    let dateString = new Date(input.value).toLocaleDateString(undefined, {
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric',
                    });
                    confirmationField.textContent = dateString;
                }
                else
                {
                    confirmationField.textContent = input.value || '–';
                }
            }
        }


        // Show next form page
        let nextFormStep = currentFormStep.nextElementSibling;
        if (nextFormStep)
        {
            let currentStepNumber = signUp.querySelector('.sign-up-step[data-step="' + currentFormStep.dataset.step + '"]');
            let nextStepNumber = signUp.querySelector('.sign-up-step[data-step="' + nextFormStep.dataset.step + '"]');

            currentFormStep.classList.remove('sign-up-form-step-current');
            nextFormStep.classList.add('sign-up-form-step-current');

            currentStepNumber.classList.remove('sign-up-step-current');
            nextStepNumber.classList.add('sign-up-step-current');

            // Scroll to top of form
            signUp.querySelector('.sign-up-steps').scrollIntoView({behavior: 'smooth', block: 'start'});

            if (nextFormStep.dataset.step != '5')
            {
                // Focus on first input in form if exists
                let firstInput = nextFormStep.querySelector('input, select, textarea');
                if (firstInput)
                {
                    firstInput.focus();
                }

                signUp.querySelector('.sign-up-next').style.display = '';
                signUp.querySelector('.sign-up-submit').style.display = 'none';
            }
            else
            {
                signUp.querySelector('.sign-up-next').style.display = 'none';
                signUp.querySelector('.sign-up-submit').style.display = '';
            }

            return;
        }

        // Otherwise submit form
        signUp.submit();
    }

    let signUpFormPrevious = e.target.closest('.sign-up-previous');
    if (signUpFormPrevious)
    {
        e.preventDefault();

        let signUp = signUpFormPrevious.closest('.sign-up');
        let currentFormStep = signUp.querySelector('.sign-up-form-step-current');
        if (!currentFormStep)
        {
            return;
        }

        let previousFormStep = currentFormStep.previousElementSibling;
        if (previousFormStep)
        {
            let currentStepNumber = signUp.querySelector('.sign-up-step[data-step="' + currentFormStep.dataset.step + '"]');
            let previousStepNumber = signUp.querySelector('.sign-up-step[data-step="' + previousFormStep.dataset.step + '"]');

            currentFormStep.classList.remove('sign-up-form-step-current');
            previousFormStep.classList.add('sign-up-form-step-current');

            currentStepNumber.classList.remove('sign-up-step-current');
            previousStepNumber.classList.add('sign-up-step-current');

            // Scroll to top of form
            signUp.querySelector('.sign-up-steps').scrollIntoView({behavior: 'smooth', block: 'start'});

            // Focus on first input in form if exists
            let firstInput = previousFormStep.querySelector('input, select, textarea');
            if (firstInput)
            {
                firstInput.focus();
            }

            return;
        }
    }

    let nextPage = e.target.closest('.pagination a');
    if (nextPage)
    {
        e.preventDefault();

        let pagination = nextPage.closest('.pagination');
        let targetSelector = nextPage.dataset.target;
        let containerSelector = nextPage.dataset.container;
        pagination.classList.add('loading');

        let items = pagination.closest(containerSelector).querySelector(targetSelector);
        fetch(nextPage.href).then(r => r.text()).then(html => {
            pagination.classList.remove('loading');

            // extract items from html
            let parser = new DOMParser();
            let doc = parser.parseFromString(html, 'text/html');
            let children = doc.querySelector(targetSelector).children;

            for (let child of Array.from(children))
            {
                items.append(child);
            }

            // update pagination
            pagination.innerHTML = doc.querySelector('.pagination').innerHTML;
        });
    }
});

document.addEventListener('submit', e => {
    if (!e.target.matches)
    {
        return;
    }

    if (e.target.matches('.footer-newsletter-form'))
    {
        e.preventDefault();

        e.target.classList.remove('footer-newsletter-errored');
        e.target.classList.remove('footer-newsletter-successful');
        e.target.classList.add('footer-newsletter-loading');

        let token = e.target.querySelector('input[name="token"]').value;
        let email = e.target.querySelector('input[name="email"]').value;
        signup(email, token).then(r => {
            e.target.classList.remove('footer-newsletter-loading');

            if (r.success)
            {
                e.target.classList.add('footer-newsletter-successful');
            }
            else
            {
                e.target.classList.add('footer-newsletter-errored');

                let errorMessage = document.getElementById('footer-newsletter-error-message');
                let errorTexts = {
                    'invalid_email': 'Het opgegeven e-maiadress is ongeldig',
                    'already_subscribed': 'Je bent al ingeschreven op de nieuwsbrief',
                    'invalid_token': 'Er is een fout opgetreden, probeer het later opnieuw',
                    'internal_server_error': 'Er is een fout opgetreden, probeer het later opnieuw',
                };
                errorMessage.innerText = errorTexts[r.error] || '';
            }
        }).catch(console.log);
    }

    if (e.target.matches('.activity-sign-up-inner'))
    {
        e.preventDefault();

        let id = e.target.dataset.activity;
        e.target.classList.remove('error');

        fetch('/activiteit-aanmelden?activity=' + id, {
            method: 'POST',
            body: new URLSearchParams(new FormData(e.target)),
            headers: {
                'Accept': 'application/json'
            }
        }).then(r => r.json()).then(r => {
            console.log(r);
            if (r.success)
            {
                e.target.classList.add('success');
            }
            else
            {
                e.target.classList.add('error');
                let errorMessage = e.target.querySelector('.activity-sign-up-error');
                errorMessage.innerText = r.error;
            }
        }).catch(console.log);
    }

    if (e.target.matches('.locations-filters'))
    {
        e.preventDefault();
        let searchParams = new URLSearchParams(new FormData(e.target));
        loadLocationFilters('?' + searchParams);
    }
})

function loadLocationFilters(url)
{
    let locations = document.querySelector('.locations');
    locations.classList.add('loading');
    locations.classList.remove('locations-showing-filters');

    // Replace url in url bar
    window.history.replaceState({}, '', url);

    fetch(url).then(r => r.text()).then(html => {
        locations.textContent = '';

        // extract .locations from html
        let parser = new DOMParser();
        let doc = parser.parseFromString(html, 'text/html');
        let children = doc.querySelector('.locations').children;

        for (let child of Array.from(children))
        {
            locations.append(child);
        }

        locations.classList.remove('loading');
    }).catch(console.error);
}