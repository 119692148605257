document.addEventListener('DOMContentLoaded', () => {
    window.enabled_cookies = '';
    let cookiePreferencesSet = false;
    let cookies = document.cookie.split(/;/);
    for (let cookie of cookies)
    {
        let parts = cookie.split(/=/, 2);
        if (parts[0].trim() === 'cookie_preferences')
        {
            cookiePreferencesSet = true;
            window.enabled_cookies = parts[1].trim();
        }
    }

    document.querySelector('#cookie-notice').classList.toggle('visible', !cookiePreferencesSet && !document.body.classList.contains('privacy-policy'));
    dataLayer.push({ event: 'cookie_preferences_updated' });
});


document.addEventListener('click', e => {
    if (!e.target.closest)
    {
        return;
    }

    if (e.target.closest('#cookie-notice-buttons-customize'))
    {
        e.preventDefault();
        document.querySelector('#cookies-analytics').checked = false;
        document.querySelector('#cookies-marketing').checked = false;
        document.querySelector('#cookies-analytics').focus();
        document.querySelector('#cookie-notice').classList.add('customize');
    }

    if (e.target.closest('.cookie-notice-buttons-only-essential'))
    {
        e.preventDefault();
        setCookiePreferences([]);
    }

    if (e.target.closest('.cookie-notice-buttons-save'))
    {
        e.preventDefault();

        let cookiePreferences = ['analytics', 'marketing'].filter(type => document.querySelector('#cookies-' + type).checked);
        setCookiePreferences(cookiePreferences);
    }

    let expand = e.target.closest('.agenda-item-date-expand');
    if (expand)
    {
        e.preventDefault();
        expand.parentNode.querySelectorAll('.agenda-item-date-hidden').forEach(elm => elm.classList.remove('agenda-item-date-hidden'));
        expand.remove();
    }
});

function setCookiePreferences(cookiePreferences)
{
    let expires = new Date();
    expires.setTime(expires.getTime() + 15552000000); // 6 months

    // Set cookie preferences cookie
    document.cookie = 'cookie_preferences=' + cookiePreferences.join(',') + ';expires=' + expires.toUTCString() + ';path=/';
    window.enabled_cookies = cookiePreferences.join(',');

    // Hide cookie notice
    document.querySelector('#cookie-notice').style.display = 'none';

    dataLayer.push({ event: 'cookie_preferences_updated' });

}