import { loadMap, createHTMLMarker } from './map.js';

document.addEventListener('DOMContentLoaded', () => {
    // For each .google-maps-map in the DOM, initialize a map
    document.querySelectorAll('.google-maps-map').forEach(mapElement => {
        loadMap(mapElement.dataset.apiKey).then(() => {
            let getDefault = (value, defaultValue) => typeof value === 'undefined' ? defaultValue : value;

            let div = document.createElement('div');
            div.style.width = '100%';
            div.style.height = '100%';
            mapElement.appendChild(div);

            let map = new google.maps.Map(div, {
                center: {
                    lat: parseFloat(mapElement.dataset.latitude),
                    lng: parseFloat(mapElement.dataset.longitude)
                },
                zoom: parseInt(mapElement.dataset.zoom),
                scrollwheel: getDefault(mapElement.dataset.scrollwheel, '1') === '1',
                disableDefaultUI: getDefault(mapElement.dataset.disableDefaultUi, '0') === '1',
                clickableIcons: !getDefault(mapElement.dataset.disableDefaultUi, '0') === '1',
                mapId: mapElement.dataset.mapId
            });

            const fitToBounds = () => {
                // let offset = parseInt(mapElement.dataset.fitToBoundsOffset);
                // map.fitBounds(bounds, { top: offset, right: offset, bottom: offset, left: offset });
            };

            // add markers
            for (let markerElement of mapElement.querySelectorAll('.google-maps-marker'))
            {
                let marker = createHTMLMarker({
                    latlng: new  google.maps.LatLng(parseFloat(markerElement.dataset.latitude), parseFloat(markerElement.dataset.longitude)),
                    elm: markerElement,
                    map: map
                });
            }

            mapElement.addEventListener('google_maps.marker_added', fitToBounds);
            fitToBounds();
        });
    });
});